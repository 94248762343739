import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import Navbar from "./components/Universal/Navbar";
import Search from "./pages/Search";
import Browse from "./pages/Browse";
import Watchlist from "./pages/Watchlist";
import LiveTV from "./pages/LiveTV";
import Settings from "./pages/Settings";
import View from "./pages/View";

function App() {
  const [baseApiUrl, setBaseApiUrl] = useState("https://api.themoviedb.org/3");
  const [baseImageUrl, setBaseImageUrl] = useState(
    "https://image.tmdb.org/t/p/original/"
  );
  const [TMDBapiKey, setTMDBapiKey] = useState(
    "5ef2e43b76a9a0d14b50c54287751870"
  );

  const [todaysTrending, setTodaysTrending] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState("");

  const [popularMovies, setPopularMovies] = useState([]);

  useEffect(() => {
    const getTodaysTrending = async () => {
      try {
        const response = await fetch(
          `${baseApiUrl}/trending/movie/day?api_key=${TMDBapiKey}`
        );
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          setTodaysTrending(data.results[0]);
          setBackgroundImage(
            `${baseImageUrl}${data.results[0]?.backdrop_path}`
          );
        } else {
          console.log("No trending data available");
        }
      } catch (error) {
        console.error("Error fetching trending data:", error);
      }
    };

    const getPopularMovies = async () => {
      try {
        const response = await fetch(
          `${baseApiUrl}/movie/popular?api_key=${TMDBapiKey}`
        );
        const data = await response.json();

        if (data.results && data.results.length > 0) {
          setPopularMovies(data.results);
          console.log(data.results);
        } else {
          console.log("No popular data available");
        }
      } catch (error) {
        console.error("Error fetching popular movies:", error);
      }
    };

    getTodaysTrending();
    getPopularMovies();
  }, []);

  return (
    <div className="flex flex-row w-full h-[200rem] bg-black text-white overflow-x-clip">
      <Router>
        <div className="absolute md:relative bottom-10 md:bottom-0 right-10 md:right-0 md:w-1/6 bg-black/50 md:bg-transparent px-6 md:px-0 py-6 md:py-0 rounded-full z-10">
          <Navbar />
        </div>
        <div className="md:w-5/6 right-0">
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  todaysTrending={todaysTrending}
                  backgroundImage={backgroundImage}
                  popularMovies={popularMovies}
                  baseImageUrl={baseImageUrl}
                />
              }
            />
            <Route path="/search" element={<Search />} />
            <Route path="/browse" element={<Browse />} />
            <Route path="/watchlist" element={<Watchlist />} />
            <Route path="/live" element={<LiveTV />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/view/:id" element={<View />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
