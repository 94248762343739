import { Bookmark, Play } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const PopularMovies = ({ popularMovies, baseImageUrl }) => {
  return (
    <div>
      <h1 className="text-3xl font-semibold mb-10">Popular</h1>
      <div className="flex flex-row gap-x-4 overflow-x-scroll h-[80rem]">
        {popularMovies.map((movie, index) => (
          <div
            key={index}
            className="w-[200px] p-2 rounded-xl flex flex-col gap-y-2 group hover:bg-gray-900/50 transition-colors hover:cursor-pointer relative"
          >
            <img
              src={baseImageUrl + movie.poster_path}
              alt={movie.title + " Poster"}
              className="rounded-lg"
            />
            <h3 className="text-lg font-semibold">{movie.title}</h3>
            <p className="text-gray-400">{movie.release_date}</p>
            <Link className="w-10 h-10 flex justify-center items-center bg-gray-800 rounded-full">
              <Play />
            </Link>

            {/* EXTRA INFO PANEL */}
            <div className="absolute top-0 left-full w-[350px] ml-4 z-20 opacity-0 group-hover:opacity-100 bg-gray-900 p-2 rounded-xl flex flex-col gap-y-2 transition-opacity pointer-events-none">
              <img
                src={baseImageUrl + movie.backdrop_path}
                alt={movie.title + " Backdrop"}
                className="rounded-lg"
              />
              <h2 className="text-2xl font-bold">{movie.title}</h2>
              <p>{movie.overview}</p>
              <div className="flex flex-col md:flex-row gap-6 mt-4">
                <div className="flex flex-row gap-x-2 bg-gray-800 px-6 py-2 rounded-full hover:bg-gray-600 transition-colors max-w-60">
                  <Play />
                  <Link>Play</Link>
                </div>
                <div className="flex flex-row gap-x-2 bg-gray-800 px-6 py-2 rounded-full hover:bg-gray-600 transition-colors max-w-60">
                  <Bookmark />
                  <Link>Add to Watchlist</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularMovies;
