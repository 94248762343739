import { Bookmark, Play, Star } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import TodaysTrending from "../components/Home/TodaysTrending";
import PopularMovies from "../components/Home/PopularMovies";

const Home = ({
  todaysTrending,
  backgroundImage,
  popularMovies,
  baseImageUrl,
}) => {
  return (
    <div className="h-full w-max bg-black text-white px-12 pt-[20vh] md:pt-[40vh]">
      <div
        className="absolute top-0 left-0 w-full h-full z-0"
        style={{
          backgroundImage: backgroundImage
            ? `linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 0.3) 100%
        ), url(${backgroundImage})`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="relative z-10 flex flex-col gap-y-40 w-full">
        <TodaysTrending todaysTrending={todaysTrending} />
        <PopularMovies
          popularMovies={popularMovies}
          baseImageUrl={baseImageUrl}
        />
      </div>
    </div>
  );
};

export default Home;
