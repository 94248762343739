import React from "react";
import { useParams } from "react-router-dom";

const View = () => {
  const { id } = useParams();
  const embedUrl = `https://vidsrc.dev/embed/movie/${id}`;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full p-4">
      <h1 className="text-3xl font-bold text-white mb-6">Now Viewing</h1>
      <iframe
        title="Movie Embed"
        src={embedUrl}
        allowFullScreen
        frameBorder="0"
        className="w-full max-w-5xl h-[500px] md:h-[600px] rounded-lg border-2 border-gray-800 shadow-lg"
      />
    </div>
  );
};

export default View;
