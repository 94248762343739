import { Home, Search, List, Bookmark, Tv2, Settings } from "lucide-react";

export const navLinks = [
  { id: 1, title: "Home", link: "/", icon: <Home size={32} /> },
  { id: 2, title: "Search", link: "/search", icon: <Search size={32} /> },
  {
    id: 3,
    title: "Browse",
    link: "/browse",
    icon: <List size={32} />,
    options: [
      { id: 1, title: "Movies", link: "/browse/movies" },
      { id: 2, title: "Series", link: "/browse/series" },
      { id: 3, title: "Anime", link: "/browse/anime" },
    ],
  },
  {
    id: 4,
    title: "Watchlist",
    link: "/watchlist",
    icon: <Bookmark size={32} />,
  },
  { id: 5, title: "Live TV", link: "/live", icon: <Tv2 size={32} /> },
  { id: 6, title: "Settings", link: "/settings", icon: <Settings size={32} /> },
];
