import React, { useState } from "react";
import { navLinks } from "../../constants/Navbar";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("/");

  return (
    <div className="md:h-screen md:w-full md:flex md:justify-center md:items-center z-10">
      <div className="flex flex-col gap-8">
        {navLinks.map((nav) => (
          <Link
            key={nav.id}
            to={nav.link}
            className={` hover:text-gray-100 hover:scale-105 transition-all relative group ${
              nav.link === activeLink ? "text-white" : "text-gray-400"
            }`}
            onClick={() => setActiveLink(nav.link)}
          >
            {nav.icon}
            <div className="absolute top-0 ml-4 left-full min-w-max bg-gray-800 px-4 py-1 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity">
              {nav.title}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
