import { Bookmark, Play, Star } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const TodaysTrending = ({ todaysTrending }) => {
  return (
    <div className="flex flex-col gap-y-4 md:w-[600px] w-4/5">
      <h1 className="text-5xl font-bold">{todaysTrending.title}</h1>
      <div className="flex flex-row gap-x-6 text-gray-200">
        <div className="flex flex-row gap-x-2">
          <Star />
          <p>{todaysTrending.vote_average}</p>
        </div>
        <p>{todaysTrending.release_date}</p>
      </div>
      <p>{todaysTrending.overview}</p>
      <div className="flex flex-col md:flex-row gap-6 mt-4">
        <div className="flex flex-row gap-x-2 bg-gray-800 px-6 py-2 rounded-full hover:bg-gray-600 transition-colors max-w-60">
          <Play />
          <Link to={`/view/${todaysTrending.id}`}>Play</Link>
        </div>
        <div className="flex flex-row gap-x-2 bg-gray-800 px-6 py-2 rounded-full hover:bg-gray-600 transition-colors max-w-60">
          <Bookmark />
          <Link>Add to Watchlist</Link>
        </div>
      </div>
    </div>
  );
};

export default TodaysTrending;
